// extracted by mini-css-extract-plugin
export var GlobalFuelTechMobile = "FuelMobile-module--GlobalFuelTechMobile--362e7";
export var cardBg = "FuelMobile-module--cardBg--a7407";
export var cards = "FuelMobile-module--cards--569df";
export var cardsContent = "FuelMobile-module--cardsContent--c449c";
export var description = "FuelMobile-module--description--7c50d";
export var heading = "FuelMobile-module--heading--efaf5";
export var iconssCard = "FuelMobile-module--iconssCard--bfe8e";
export var nav = "FuelMobile-module--nav--3c9df";
export var navItem = "FuelMobile-module--nav-item--d3552";
export var navbarBlock = "FuelMobile-module--navbarBlock--cc7f6";
export var tabData = "FuelMobile-module--tabData--87882";
export var tech = "FuelMobile-module--tech--654a2";
export var techIcon = "FuelMobile-module--techIcon--6fdbd";
export var techImg = "FuelMobile-module--techImg--5ccf4";
export var technologyIcon = "FuelMobile-module--technologyIcon--a1d0e";