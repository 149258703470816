import { Script, graphql } from "gatsby"
import React from "react"
import ContactSales from "../components/common/ContactSales"
import Faqs from "../components/common/Faqs2"
import SEORevamp from "../components/common/SEO_Revamp"
import MainLayout from "../layouts/MainLayout"
import Banner from "../components/web-application/Banner"
import ItStaff from "../components/web-application/ItStaff"
import Shape from "../components/web-application/Shape"
import Need from "../components/web-application/Need"
import PackedIndustry from "../components/web-application/PackedIndustry"
import Projects from "../components/web-application/Projects"
import Opportunity from "../components/web-application/Opportunity"
import Strategic from "../components/web-application/Strategic"
import Fuel from "../components/web-application/Fuel"
import FuelMobile from "../components/web-application/FuelMobile"
import LatestTechnologies from "../components/web-application/LatestTechnologies"
import WhyChoose from "../components/web-application/WhyChoose"
import ClientsWeb from "../components/web-application/ClientsWeb"
import StrategicMobile from "../components/web-application/StrategicMobile"
import ProjectsMobile from "../components/web-application/ProjectsMobile"
const webdevelopment = ({ data }) => {
  // console.log(data)
  const [isMobile, setIsMobile] = React.useState(false)
  const [isTablet, setIsTablet] = React.useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 767) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 767)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const heroSection = data?.strapiPage?.sections[0]
  const itstaff = data?.strapiPage?.sections[1]
  const shape = data?.strapiPage?.sections[2]
  const need = data?.strapiPage?.sections[3]
  const packed = data?.strapiPage?.sections[4]
  const fuelTech = data?.strapiPage?.sections[5]
  const lastestTechnology = data?.strapiPage?.sections[6]
  const strategy = data?.strapiPage?.sections[7]
  const whychoose = data?.strapiPage?.sections[8]
  const opportunity = data?.strapiPage?.sections[9]
  const happyClients = data?.strapiPage?.sections[10]
  const projects = data?.strapiPage?.sections[11]
  const faqs = data?.strapiPage?.sections[12]
  //   const testimonials = data?.testimonials?.sections[0]

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <MainLayout schemas={bodySchema}>
      <Banner strapiData={heroSection} breadCrumb={data?.strapiPage?.buttons} />
      <ItStaff strapiData={itstaff} />
      <Shape strapiData={shape} />
      <Need strapiData={need} />
      <PackedIndustry strapiData={packed} />
      {!isMobile && !isTablet ? (
        <Fuel strapiData={fuelTech} />
      ) : (
        <FuelMobile strapiData={fuelTech} />
      )}
      <LatestTechnologies strapiData={lastestTechnology} />
      {!isMobile && !isTablet ? (
        <Strategic strapiData={strategy} />
      ) : (
        <StrategicMobile strapiData={strategy} />
      )}
      <WhyChoose strapiData={whychoose} />
      <Opportunity strapiData={opportunity} />
      <ClientsWeb strapiData={happyClients} />
      {!isMobile && !isTablet ? (
        <Projects strapiData={projects} />
      ) : (
        <ProjectsMobile strapiData={projects} />
      )}
      <Faqs strapiData={faqs} />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query ElixirPage {
    strapiPage(slug: { eq: "web-development" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
            url
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
    testimonials: strapiPage(slug: { eq: "testimonial" }) {
      sections {
        title
        subTitle
        cards {
          title
          subTitle
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default webdevelopment

export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })
  return (
    <>
      <Script
        src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
        strategy="post-hydrate"
      />
      <SEORevamp
        title={metaTitle}
        description={metaDescription}
        schemas={headSchema}
        image="https://invozone-backend.s3.amazonaws.com/leading_react_native_app_development_company_e5e33e137c.webp"
      />
    </>
  )
}
