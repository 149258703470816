// extracted by mini-css-extract-plugin
export var GlobalFuelTechStacksWeb = "Fuel-module--GlobalFuelTechStacksWeb--cac60";
export var cardBg = "Fuel-module--cardBg--00216";
export var cards = "Fuel-module--cards--706c5";
export var cardsContent = "Fuel-module--cardsContent--9a68b";
export var description = "Fuel-module--description--c7326";
export var heading = "Fuel-module--heading--9f18e";
export var iconssCard = "Fuel-module--iconssCard--deeff";
export var nav = "Fuel-module--nav--b0268";
export var navItem = "Fuel-module--nav-item--97028";
export var navbarBlock = "Fuel-module--navbarBlock--4d362";
export var tabData = "Fuel-module--tabData--037f2";
export var tech = "Fuel-module--tech--51c88";
export var techIcon = "Fuel-module--techIcon--f5035";
export var techImg = "Fuel-module--techImg--d4491";
export var technologyIcon = "Fuel-module--technologyIcon--07a85";