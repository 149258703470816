// extracted by mini-css-extract-plugin
export var crossImg = "LatestTechnologies-module--crossImg--caf3c";
export var description = "LatestTechnologies-module--description--87cfc";
export var description2 = "LatestTechnologies-module--description2--aec4b";
export var faqButton = "LatestTechnologies-module--faqButton--25ecb";
export var faqsNum = "LatestTechnologies-module--faqsNum--ed23f";
export var faqsSub = "LatestTechnologies-module--faqsSub--274fd";
export var head = "LatestTechnologies-module--head--3e143";
export var headr = "LatestTechnologies-module--headr--d3aed";
export var quesColor = "LatestTechnologies-module--quesColor--fb076";
export var quesColorSelected = "LatestTechnologies-module--quesColorSelected--66d96";
export var reverse = "LatestTechnologies-module--reverse--f57a6";
export var sectionLatestTechnologyWeb = "LatestTechnologies-module--sectionLatestTechnologyWeb--c46f5";
export var serial = "LatestTechnologies-module--serial--c86e7";
export var serial2 = "LatestTechnologies-module--serial2--54e6a";
export var textColor = "LatestTechnologies-module--textColor--48c80";