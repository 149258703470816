import React from "react"
import * as styles from "./ItStaff.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"

const ItStaff = ({ strapiData, noCode }) => {
  return (
    <React.Fragment>
      <div className={styles.itStaffCards}>
        <Container>
          <Row className={styles.CardWrapperWebApplication}>
            {strapiData?.cards?.map((e, i) => (
              <div className="gap-30" key={i}>
                <div
                  className={`${styles.card} ${
                    noCode ? styles.noCodeCard : ""
                  }`}
                >
                  <lottie-player
                    autoplay
                    loop
                    src={e?.image1[0]?.localFile?.publicURL}
                    style={{ height: "60px", width: "60px", margin: "0" }}
                  />
                  <h3>{e?.title}</h3>
                </div>
              </div>
            ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ItStaff
