import React, { useState } from "react"
import Nav from "react-bootstrap/Nav"
import Container from "react-bootstrap/Container"
import Tab from "react-bootstrap/Tab"
import "./Fuel.scss"
import * as styles from "./Fuel.module.scss"

const data = [
  {
    title: "Backend",
    tech: [
      {
        name: "Ruby",
        icon: "https://invozone-backend.s3.amazonaws.com/ruby_on_rails_f46192ca94.svg",
      },
      {
        name: "Python",
        icon: "https://invozone-backend.s3.amazonaws.com/python_2c94b7b2ba.svg",
      },
      {
        name: "Java",
        icon: "https://invozone-backend.s3.amazonaws.com/java_ea77870d12.svg",
      },
      {
        name: "PHP",
        icon: "https://invozone-backend.s3.amazonaws.com/php_5b4ff59f57.svg",
      },
      {
        name: "Go",
        icon: "https://invozone-backend.s3.amazonaws.com/golang_af33d97d05.svg",
      },
      {
        name: "Kotlin",
        icon: "https://invozone-backend.s3.amazonaws.com/kotlin_13d275b8c5.svg",
      },
      {
        name: "JavaScript",
        icon: "https://invozone-backend.s3.amazonaws.com/javascript_3eb254d0c1.svg",
      },
      {
        name: "C#",
        icon: "https://invozone-backend.s3.amazonaws.com/c_sharp_9f096f5266.svg",
      },
      {
        name: "Elixir",
        icon: "https://invozone-backend.s3.amazonaws.com/elixir_9a4aafe1ac.svg",
      },
      {
        name: "Rust",
        icon: "https://invozone-backend.s3.amazonaws.com/rust_8dcee0b849.svg",
      },
    ],
  },
  {
    title: "Frontend",
    tech: [
      {
        name: "Angular",
        icon: "https://invozone-backend.s3.amazonaws.com/angular_accf40208e.svg",
      },
      {
        name: "Vue",
        icon: "https://invozone-backend.s3.amazonaws.com/vue_fc7995e57d.svg",
      },
      {
        name: "React",
        icon: "https://invozone-backend.s3.amazonaws.com/react_9e4d4f1636.svg",
      },
      {
        name: "D3.js",
        icon: "https://invozone-backend.s3.amazonaws.com/d3_dot_js_2bfab29c6d.svg",
      },
      {
        name: "AngularJS",
        icon: "https://invozone-backend.s3.amazonaws.com/angular_accf40208e.svg",
      },
      {
        name: "HTML",
        icon: "https://invozone-backend.s3.amazonaws.com/html_b98b41dd3a.svg",
      },
      {
        name: "CSS",
        icon: "https://invozone-backend.s3.amazonaws.com/css_23fa4ecec3.svg",
      },
      {
        name: "Tailwind CSS",
        icon: "https://invozone-backend.s3.amazonaws.com/tailwind_css_60274236cf.svg",
      },
      {
        name: "Bootstrap",
        icon: "https://invozone-backend.s3.amazonaws.com/bootstrap_bb20df2e8a.svg",
      },
      {
        name: "Swift",
        icon: "https://invozone-backend.s3.amazonaws.com/swift_bd4f360fb3.svg",
      },
      {
        name: "Flutter",
        icon: "https://invozone-backend.s3.amazonaws.com/flutter_c0ad0503c7.svg",
      },
    ],
  },
  {
    title: "Databases",
    tech: [
      {
        name: "SQL server",
        icon: "https://invozone-backend.s3.amazonaws.com/microsoft_sql_server_e7e5615bfb.svg",
      },
      {
        name: "MySQL",
        icon: "https://invozone-backend.s3.amazonaws.com/mysql_8b65d4e2ad.svg",
      },
      {
        name: "Azure SQL",
        icon: "https://invozone-backend.s3.amazonaws.com/azure_0b43c63ff1.svg",
      },
      {
        name: "Oracle",
        icon: "https://invozone-backend.s3.amazonaws.com/oracle_9ca77142cd.svg",
      },
      {
        name: "ElasticSearch",
        icon: "https://invozone-backend.s3.amazonaws.com/elasticsearch_bf7cbeea93.svg",
      },
      {
        name: "PostgreSQL",
        icon: "https://invozone-backend.s3.amazonaws.com/postgresql_2356a618ea.svg",
      },
      {
        name: "MongoDB",
        icon: "https://invozone-backend.s3.amazonaws.com/mongodb_5108764222.svg",
      },
      {
        name: "AmazonRDS",
        icon: "https://invozone-backend.s3.amazonaws.com/aws_rds_db5b00e503.svg",
      },
      {
        name: "Dynamo",
        icon: "https://invozone-backend.s3.amazonaws.com/aws_dynamodb_6c073e5364.svg",
      },
      {
        name: "Redis",
        icon: "https://invozone-backend.s3.amazonaws.com/redis_02864b6029.svg",
      },
      {
        name: "MariaDB",
        icon: "https://invozone-backend.s3.amazonaws.com/mariadb_ac3bd418b3.svg",
      },
      {
        name: "SQLite",
        icon: "https://invozone-backend.s3.amazonaws.com/sqlite_38e23119be.svg",
      },
    ],
  },
  {
    title: "Cloud & DevOps",
    tech: [
      {
        name: "AWS",
        icon: "https://invozone-backend.s3.amazonaws.com/aws_b29d4b8cfa.svg",
      },
      {
        name: "Google Cloud",
        icon: "https://invozone-backend.s3.amazonaws.com/google_cloud_b7c7d78ee0.svg",
      },
      {
        name: "Kubernetes",
        icon: "https://invozone-backend.s3.amazonaws.com/kubernetes_f3c7894e35.svg",
      },
      {
        name: "Docker",
        icon: "https://invozone-backend.s3.amazonaws.com/docker_3074c982fb.svg",
      },
      {
        name: "Azure",
        icon: "https://invozone-backend.s3.amazonaws.com/azure_0b43c63ff1.svg",
      },
      {
        name: "Jenkins",
        icon: "https://invozone-backend.s3.amazonaws.com/jenkins_c4e3561565.svg",
      },
    ],
  },
  {
    title: "Testing",
    tech: [
      {
        name: "Postman",
        icon: "https://invozone-backend.s3.amazonaws.com/postman_1b9276abd0.svg",
      },
      {
        name: "Jmeter",
        icon: "https://invozone-backend.s3.amazonaws.com/jmeter_f5bce02836.svg",
      },
      {
        name: "Selenium",
        icon: "https://invozone-backend.s3.amazonaws.com/selenium_2cfbc9882b.svg",
      },
      {
        name: "Cypress",
        icon: "https://invozone-backend.s3.amazonaws.com/cypress_7e571b874d.svg",
      },
      {
        name: "BrowserStack",
        icon: "https://invozone-backend.s3.amazonaws.com/browserstack_858d0150a1.svg",
      },
      {
        name: "Bugzilla",
        icon: "https://invozone-backend.s3.amazonaws.com/bugzilla_dc22895470.svg",
      },
      {
        name: "SoapUI",
        icon: "https://invozone-backend.s3.amazonaws.com/soapui_fde55b50e3.svg",
      },
    ],
  },
  {
    title: "Frameworks",
    tech: [
      {
        name: "Django",
        icon: "https://invozone-backend.s3.amazonaws.com/django_bc7d32b851.svg",
      },
      {
        name: "Flask",
        icon: "https://invozone-backend.s3.amazonaws.com/flask_5d47d43713.svg",
      },
      {
        name: "FastAPI",
        icon: "https://invozone-backend.s3.amazonaws.com/fastapi_c54992c5d8.svg",
      },
      {
        name: "Ruby on Rails",
        icon: "https://invozone-backend.s3.amazonaws.com/ruby_on_rails_f46192ca94.svg",
      },
      {
        name: "Sprint Boot",
        icon: "https://invozone-backend.s3.amazonaws.com/spring_boot_d56b1daec9.svg",
      },
      {
        name: "ASP.NET",
        icon: "https://invozone-backend.s3.amazonaws.com/asp_dot_net_665eb85db0.svg",
      },
      {
        name: "Phoenix",
        icon: "https://invozone-backend.s3.amazonaws.com/phoenix_d70f77ea9c.svg",
      },
      {
        name: "Gin",
        icon: "https://invozone-backend.s3.amazonaws.com/gin_3c4dbc3ea5.svg",
      },
      {
        name: "Laravel",
        icon: "https://invozone-backend.s3.amazonaws.com/laravel_0b5614b072.svg",
      },
      {
        name: "Rocket",
        icon: "https://invozone-backend.s3.amazonaws.com/rocket_1c88744fd4.svg",
      },
      {
        name: "NodeJs",
        icon: "https://invozone-backend.s3.amazonaws.com/nodejs_f1ad2a2c65.svg",
      },
      {
        name: "Express",
        icon: "https://invozone-backend.s3.amazonaws.com/express_8126cd8414.svg",
      },
      {
        name: "Ktor",
        icon: "https://invozone-backend.s3.amazonaws.com/ktor_1d88cf84c5.svg",
      },
    ],
  },
]

const Fuel = ({ strapiData }) => {
  const [activeTab, setActiveTab] = useState(data[0]?.title)

  const handleTabClick = label => {
    setActiveTab(label)
  }

  return (
    <div className="FuelWebTabStacks">
      <div className={styles.GlobalFuelTechStacksWeb}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          ></h2>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={`${data[0]?.title}`}
          >
            <div className={styles.cardsContent}>
              <div>
                <Nav variant="pills" className={`FuelWebTabStacks`}>
                  {data &&
                    data?.map((e, i) => (
                      <Nav.Item key={i} className="cards">
                        <div
                          eventKey={`${e?.title}`}
                          className={`tabLink ${
                            activeTab === e.title ? "active" : ""
                          }`}
                          onClick={() => handleTabClick(e.title)}
                        >
                          <div className={styles.tabData}>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: e?.title,
                              }}
                            />
                          </div>
                        </div>
                      </Nav.Item>
                    ))}
                </Nav>
              </div>
              <Tab.Content className="contentBlock">
                {data &&
                  data?.map((e, i) => (
                    <div
                      key={i}
                      className={`tab-pane ${
                        activeTab === e.title ? "active" : ""
                      }`}
                    >
                      <div className={styles.techIcon}>
                        {e?.tech &&
                          e?.tech?.map((el, i) => (
                            <div className={`${styles.iconssCard}`} key={i}>
                              <div className={styles.technologyIcon}>
                                <div className={styles.techImg}>
                                  <img
                                    decoding="async"
                                    loading="lazy"
                                    src={el?.icon}
                                    alt={el?.name}
                                  />
                                </div>
                                <h3>{el?.name}</h3>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
              </Tab.Content>
            </div>
          </Tab.Container>
        </Container>
      </div>
    </div>
  )
}
export default Fuel
