// extracted by mini-css-extract-plugin
export var arrowPics1 = "Strategic-module--arrowPics1--c36ed";
export var arrowPics2 = "Strategic-module--arrowPics2--94c0d";
export var arrowPics3 = "Strategic-module--arrowPics3--16a4e";
export var arrowPics4 = "Strategic-module--arrowPics4--8edff";
export var arrowPics5 = "Strategic-module--arrowPics5--6e7c3";
export var bgImage = "Strategic-module--bgImage--34d7c";
export var dottedLine1 = "Strategic-module--dottedLine1--40763";
export var giffBg = "Strategic-module--giffBg--f529c";
export var giffBg2 = "Strategic-module--giffBg2--33a87";
export var giffBg3 = "Strategic-module--giffBg3--480d2";
export var giffBg4 = "Strategic-module--giffBg4--1b8e1";
export var giffBg5 = "Strategic-module--giffBg5--7fd94";
export var giffBg6 = "Strategic-module--giffBg6--1ca2e";
export var imageGiffs = "Strategic-module--imageGiffs--94e22";
export var lottieImages1 = "Strategic-module--lottieImages1--6861b";
export var lottieImages2 = "Strategic-module--lottieImages2--5b821";
export var lottieImages3 = "Strategic-module--lottieImages3--fea07";
export var lottieImages4 = "Strategic-module--lottieImages4--63b7e";
export var lottieImages5 = "Strategic-module--lottieImages5--ae328";
export var lottieImages6 = "Strategic-module--lottieImages6--fe7f3";
export var overlay = "Strategic-module--overlay--40049";
export var processData = "Strategic-module--processData--552b6";
export var processData2 = "Strategic-module--processData2--d4fbb";
export var processData3 = "Strategic-module--processData3--5d03b";
export var processDes = "Strategic-module--processDes--43f53";
export var processDetails = "Strategic-module--processDetails--21bd9";
export var processes = "Strategic-module--processes--4b6e3";
export var rightProcess = "Strategic-module--rightProcess--c8d6e";
export var rightProcessData = "Strategic-module--rightProcessData--a6f3d";
export var rightProcessData2 = "Strategic-module--rightProcessData2--8dad7";
export var rightProcessData3 = "Strategic-module--rightProcessData3--3eb3c";
export var rightProcessDes = "Strategic-module--rightProcessDes--818f5";
export var stagegyDescription = "Strategic-module--stagegyDescription--fd9b0";
export var strategic = "Strategic-module--strategic--ab517";
export var strategyHeading = "Strategic-module--strategyHeading--3c280";