import React from "react"
import * as styles from "./Strategic.module.scss"
import bgimg from "../../images/web-application/mainimg.png"
import overlay from "../../images/web-application/overlayimg.png"
import giffbg from "../../images/web-application/giffbg.png"
import process from "../../images/web-application/up.svg"
import borderStock from "../../images/web-application/borderStroke.svg"
import Container from "react-bootstrap/Container"

const Strategic = ({ strapiData }) => {
  const lottieImagesData = [
    {
      lottie:
        "https://invozone-backend.s3.amazonaws.com/qa_testing_cd85af3810.json",
    },
    {
      lottie:
        "https://invozone-backend.s3.amazonaws.com/prototyping_be30052883.json",
    },
    {
      lottie:
        "https://invozone-backend.s3.amazonaws.com/product_planning_c74cb8f9b5.json",
    },
    {
      lottie:
        "https://invozone-backend.s3.amazonaws.com/deployment_and_lanch_02153d1884.json",
    },

    {
      lottie:
        "https://invozone-backend.s3.amazonaws.com/ideation_and_stragy_5c5b8ae02c.json",
    },
    {
      lottie:
        "https://invozone-backend.s3.amazonaws.com/maintanince_9b9ca2f709.json",
    },
  ]
  const arrowImages = [
    {
      arrowImg: process,
    },
    {
      arrowImg: process,
    },
    {
      arrowImg: process,
    },
    {
      arrowImg: process,
    },
    {
      arrowImg: process,
    },
  ]
  return (
    <React.Fragment>
      <div className={styles.strategic}>
        <Container>
          <h2
            className={styles.strategyHeading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <div
            className={styles.stagegyDescription}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <div
            className={` align-items-center justify-content-between d-flex ${styles.processDetails}`}
          >
            <div className={`${styles.processes}`}>
              <h3 className={`${styles.processData}`}>
                01 - Ideation & Strategy
              </h3>
              <p className={`${styles.processDes}`}>
                We get your project requirements and plan each phase to ensure
                the seamlessness of your solution & preserve transperancy.
              </p>
              <h3 className={`${styles.processData2}`}>
                02 - Product Planning
              </h3>
              <p className={`${styles.processDes}`}>
                All details like tools, back-end & front-end technologies to be
                used and APIs integration etc are defined in this stage.
              </p>
              <h3 className={`${styles.processData3}`}>03 - Prototyping</h3>
              <p className={`${styles.processDes}`}>
                The UX/UX designer prepares the design,then our top developers
                transform the design into a product with a smooth interface.
              </p>
            </div>
            <div className={`${styles.imageGiffs}`}>
              <img src={bgimg} alt="" className={styles.bgImage} />
              <img src={overlay} alt="" className={styles.overlay} />
              <img src={giffbg} alt="" className={styles.giffBg} />
              <img src={giffbg} alt="" className={styles.giffBg2} />
              <img src={giffbg} alt="" className={styles.giffBg3} />
              <img src={giffbg} alt="" className={styles.giffBg4} />
              <img src={giffbg} alt="" className={styles.giffBg5} />
              <img src={giffbg} alt="" className={styles.giffBg6} />
              {arrowImages &&
                arrowImages?.map((a, r) => (
                  <div className={styles[`arrowPics${r + 1}`]} key={r}>
                    <img src={a.arrowImg} alt="" />
                  </div>
                ))}
              <img src={borderStock} alt="" />
              {lottieImagesData &&
                lottieImagesData?.map((e, i) => (
                  <div className={styles[`lottieImages${i + 1}`]} key={i}>
                    <lottie-player
                      autoplay
                      loop
                      src={e?.lottie}
                      style={{ height: "60px", width: "60px", margin: "0px" }}
                    />
                  </div>
                ))}
            </div>
            <div className={`${styles.rightProcess}`}>
              <h3 className={`${styles.rightProcessData}`}>06 - Maintenance</h3>
              <p className={`${styles.rightProcessDes}`}>
                If you choose to continue with us, then we take care of your
                maintenance process dealing with all the updates & patches etc.
              </p>
              <h3 className={`${styles.rightProcessData2}`}>
                05 - Deployment & Launch
              </h3>
              <p className={`${styles.rightProcessDes}`}>
                After the approval from your side, our eligible deployment team
                deploys the project ensuring smooth launch of the project.
              </p>
              <h3 className={`${styles.rightProcessData3}`}>04 - QA Testing</h3>
              <p className={`${styles.rightProcessDes}`}>
                Once the product is approved from your side, the QA team starts
                testing the solution for bugs, stability, and performance.
              </p>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Strategic
